<template>
  <div style="position: relative">
    <Breadcrumb :Breadcrumb="BreadcrumbCon" />

    <div class="search">
      <div style="display: flex">
        <el-input placeholder="请输入游玩项目名称" v-model="title"></el-input>
        <el-select v-model="city" placeholder="请选择城市">
          <el-option
            v-for="item in optionsCity"
            :key="item.id"
            :label="item.country + '-' + item.city"
            :value="item.id"
          >
          </el-option>
        </el-select>

        <div>
          <el-button type="primary" @click="searchList">搜索</el-button>
          <el-button type="info" @click="reset">重置</el-button>
        </div>
      </div>
      <el-button type="primary" @click="handleCreate">发布游玩项目</el-button>
    </div>

    <div class="table_con">
      <el-table :data="tableData" border style="width: 100%" height="100%">
        <el-table-column type="index" label="序号" width="80" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }}</span>
          </template>
        </el-table-column>

        <el-table-column
          prop="images"
          label="项目主图"
          width="240"
          align="center"
        >
          <template slot-scope="scope">
            <el-image
              style="height: 120px"
              :src="scope.row.images.split(',')[0]"
              :preview-src-list="scope.row.images.split(',')"
              v-if="scope.row.images"
            >
            </el-image>
            <div v-if="!scope.row.images">暂无图片</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="goodsName"
          label="项目名称"
          align="center"
          width="250"
        >
          <template slot-scope="scope">
            <p class="activityTitle_hidden">{{ scope.row.goodsName }}</p>
            <el-popover placement="top-start" trigger="hover">
              <p class="tipck">中文：{{ scope.row.goodsName }}</p>
              <el-button type="text" slot="reference">查看完整名称</el-button>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          prop="goodsLocationName"
          label="项目位置"
          width="250"
          align="center"
        >
          <template slot-scope="scope">
            <p class="activityTitle_hidden">
              {{ scope.row.goodsLocationName }}
            </p>
            <el-popover placement="top-start" width="200" trigger="hover">
              <p class="tipck">中文：{{ scope.row.goodsLocationName }}</p>
              <el-button type="text" slot="reference">查看完整位置</el-button>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="orderCount" label="预定总量" align="center">
        </el-table-column>
        <el-table-column label="是否上架" align="center">
          <template slot-scope="scope">
            <div>{{ scope.row.isVisible ? "已上架" : "未上架" }}</div>
          </template>
        </el-table-column>
        <el-table-column label="发布时间" align="center" width="200">
          <template slot-scope="scope">
            <div>{{ scope.row.publishTime }}</div>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" align="center" width="120">
          <template slot-scope="scope">
            <el-button type="text" @click="handleDetail(scope.row)"
              >详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <Pagination
        v-show="pageLength >= 0"
        :total="pageLength"
        :limit.sync="pageSize"
        @pagination="handlePageChange"
        :page-sizes="pageSizes"
      />
    </div>
  </div>
</template>

<script>
import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import { hideLoading, showLoading } from "@/util/loading";

import Breadcrumb from "@/components/BreadCrumb";
import Pagination from "@/components/Pagination";

import { format_time_date } from "@/util/funBusiness";

export default {
  components: { Breadcrumb, Pagination },
  inject: ["reload"],
  data() {
    return {
      BreadcrumbCon: [
        {
          name: "行世界",
        },
        {
          name: "游玩项目",
        },
        {
          name: "发布游玩项目",
        },
      ], // 面包屑数据

      optionsCity: [],
      city: "",
      title: "",

      tableData: [],

      // 分页数据
      currentPage: 1, // 当前页
      pageLength: 0,
      pageSize: 10, //要传过去的数据 每页多少条数据
      pageSizes: [10, 20, 50, 100],
    };
  },
  mounted() {
    showLoading();
    const opt = {
      url: reqUrl.worldGetSightseeingList,
      method: "POST",
      params: JSON.stringify({
        sightseeingName: "",
        cityId: "",
      }),
      resFunc: (res) => {
        console.log(res.data);
        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        } else if (res.data.code == 0) {
          this.tableData = res.data.data.list;
          this.pageLength = res.data.data.total;
          this.pageSize = res.data.data.pageSize;

          this.tableData.map((item) => {
            this.changeItem(item);
          });
          hideLoading();
        }
        hideLoading();
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        hideLoading();
      },
    };
    request(opt);

    const optCity = {
      url: reqUrl.worldTravelSelectCity,
      method: "POST",
      params: JSON.stringify({
        city: "",
        country: "",
      }),
      resFunc: (res) => {
        console.log(res.data);
        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
        } else if (res.data.code == 0) {
          this.optionsCity = res.data.data.list;
        }
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
      },
    };
    request(optCity);
  },

  methods: {
    handleCreate() {
      this.$router.push({
        path: "/worldPlayListCreate",
        query: {
          type: "create",
        },
      });
    },

    searchList() {
      showLoading();
      const opt = {
        url: reqUrl.worldGetSightseeingList,
        method: "POST",
        params: JSON.stringify({
          sightseeingName: this.title,
          cityId: this.city,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          } else if (res.data.code == 0) {
            this.tableData = res.data.data.list;
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;

            this.tableData.map((item) => {
              this.changeItem(item);
            });
            hideLoading();
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
        },
      };
      request(opt);
    },

    handlePageChange(data) {
      showLoading();
      const opt = {
        url: reqUrl.worldGetSightseeingList,
        method: "POST",
        params: JSON.stringify({
          sightseeingName: this.title,
          cityId: this.city,
          pageNum: data.pageIndex,
          pageSize: data.pageSize,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          } else if (res.data.code == 0) {
            this.tableData = res.data.data.list;
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
            this.currentPage = data.pageIndex;

            this.tableData.map((item) => {
              this.changeItem(item);
            });
            hideLoading();
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
        },
      };
      request(opt);
    },

    changeItem(item) {
      item.publishTime = format_time_date(item.publishTime);
    },

    handleDetail(row) {
      this.$router.push({
        path: "/worldPlayListDetail",
        query: {
          sightseeingId: row.id,
        },
      });
    },

    // 刷新
    refresh() {
      this.reload();
    },

    reset() {
      this.refresh();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../index.scss";
</style>

<style>
.el-dialog__body {
  padding-top: 10px;
}
</style>
